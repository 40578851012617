const RESOURCE_NAME = '/units-keys-orders';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    // getStatistics(params) {
    //     return axios.get(`${RESOURCE_NAME}/statistics`, {params});
    // },
    getKeys(orderId, params) {
        return axios.get(`${RESOURCE_NAME}/${orderId}/keys`, {params});
    },
    transfer(data, params) {
        return axios.post(`${RESOURCE_NAME}/transfer`, data, {params});
    },
});