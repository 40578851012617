const config = {
    "img_baseURL": "https://hosting.prometheuspro.us/img/libauto/",

    "privatelabel": {
        "baseURL": "https://privatelabel.prometheuspro.us",
    },
    "api": {
        "baseURL": "https://api.prometheuspro.us/api",
        "ver": "pm",
    },
    "exports": {
        "baseURL": "https://api.prometheuspro.us/tmp/",
    },
    "customize": {
        "baseURL": "https://api.prometheuspro.us",
    },
}
export default config