// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.admin') ? [] : [
    {
        path: '/keys',
        name: consts.routerNames.keys.main,
        meta: {
            mainRouteName: consts.routerNames.keys.main,
            bodyGrid: "1X1",
            showHead: true,
            setRoute: {
                keys: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.keys.main) {
                        if(this.$isDevelopment) console.warn('keys', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.keys.main,
                        params: {},
                        query: {}
                    })
                },
                keyTransfer: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.keys.main) {
                        if(this.$isDevelopment) console.warn('keyTransfer', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.keys.edit.transfer,
                        params: {},
                        query: {}
                    })
                },
                keyGenerate: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.keys.main) {
                        if(this.$isDevelopment) console.warn('keyGenerate', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.keys.edit.generate,
                        params: {},
                        query: {}
                    })
                },
                keyTransferById: (route, router, orderId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.keys.main) {
                        if(this.$isDevelopment) console.warn('keyTransferById', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.keys.edit.transfer_by_id,
                        params: {
                            orderId
                        },
                        query: {}
                    })
                },
                orderHistory: (route, router, orderId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.keys.main) {
                        if(this.$isDevelopment) console.warn('orderHistory', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.keys.history.order,
                        params: {
                            orderId
                        },
                        query: {}
                    })
                },
                keysHistory: (route, router, orderId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.keys.main) {
                        if(this.$isDevelopment) console.warn('keysHistory', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.keys.history.keys,
                        params: {
                            orderId
                        },
                        query: {}
                    })
                },
                keyHistory: (route, router, keyId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.keys.main) {
                        if(this.$isDevelopment) console.warn('keyHistory', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.keys.history.key,
                        params: {
                            keyId
                        },
                        query: {}
                    })
                },
            }
        },
        component: () => import('@/views/intuit/admin/KeysPage.vue'),
        children: [
            {
                path: 'transfer',
                name: consts.routerNames.keys.edit.transfer,
                meta: {
                    sectionWidgetByRoute: true
                },
                props: {},
                components: {
                    sectionWidgetByRoute: () => import('@/components/keys/sectionInfo/KeysTransfer.vue'),
                }
            },
            {
                path: ':orderId/transfer',
                name: consts.routerNames.keys.edit.transfer_by_id,
                meta: {
                    sectionWidgetByRoute: true
                },
                props: {
                    sectionWidgetByRoute: (route) => ({
                        orderId: route.params?.orderId,
                        options: {components: ['KeysTransfer_order']},
                        time: Date.now(),
                    })
                },
                components: {
                    sectionWidgetByRoute: () => import('@/components/keys/sectionInfo/KeysTransfer.vue'),
                }
            },
            {
                path: 'generate-new-key',
                name: consts.routerNames.keys.edit.generate,
                meta: {
                    sectionWidgetByRoute: true
                },
                components: {
                    sectionWidgetByRoute: () => import('@/components/keys/sectionInfo/KeysOrderEdit.vue'),
                }
            },
            {
                path: 'order/:orderId/history',
                name: consts.routerNames.keys.history.order,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            class: 'popup-full',
                            titlePosition: 'left'
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        objectName: 'units_keys_order',
                        objectId: route.params.orderId,
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/objects_audit/objects/ObjectsChangesTableTreeList.vue'),
                }
            },
            {
                path: ':keyId/history',
                name: consts.routerNames.keys.history.key,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            class: 'popup-full',
                            titlePosition: 'left'
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        objectName: 'units_key',
                        objectId: route.params.keyId,
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/objects_audit/objects/ObjectsChangesTableTreeList.vue'),
                }
            },
            {
                path: 'keys-order/:orderId/history',
                name: consts.routerNames.keys.history.keys,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            class: 'popup-full',
                            titlePosition: 'left'
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        objectName: 'units_keys',
                        objectId: route.params.orderId,
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/objects_audit/objects/ObjectsChangesTableTreeList.vue'),
                }
            },
        ]
    },
];

export default routes;