<template>
    <section class="wizard is_new-design">
        <div class="wizard__head">
            <section class="wizard__body">
                <nav class="wizard-navigation">
                    <Stepper
                        :class="['custom-stepper', (menuList.length == 1 && 'stepper-one') ]"
                        :value="step"
                        :items="menuList"
                        :item="'menuSetting'"
                        @change="setStep"
                        v-if="!hideSteps && (!hideOneStep  && menuList.length == 1) || menuList.length > 1"
                    ><!-- v-show outbid -->
                        <template v-slot:menuSetting="{ props }">
                            <Step v-bind="props">
                                <div class="custom-step">
                                    <SvgIcon class="icon wizard__icon" :class="props.class" :name="props.icon"  />
                                </div>
                                <span class="step-label">{{props.name}}</span>
                            </Step>
                        </template>
                    </Stepper>
                    <slot></slot>
                </nav>
            </section>
        </div>
        <section class="wizard__bottom wizard-buttons">
            <button class="button button_view_base button_theme_primary mr__8"
                    :class="{'button-disabled' : !isValid}"
                    :disabled="!isValid"
                    @click="onStepNext"
            >
                {{next}}
            </button>
            <button class="button button_view_base button_theme_neutral"
                    :class="{'button-disabled' : step == 0}"
                    :disabled="step == 0"
                    @click="onStepBack"
            >
                {{back || $t('buttons.Back')}}
            </button>
        </section>
    </section>
</template>

<script>
import {Step, Stepper} from "@progress/kendo-vue-layout";

export default {
    name: "EditWizard",
    emits:['update:modelValue', 'save', 'cancel'],
    props: {
        'title': String,
        'components': Array,
        'modelValue': [Number, String],
        'action': String,
        'back': String,
        'hideSteps': {
            type: Boolean,
            default: () => false
        },
        'hideOneStep': {
            type: Boolean,
            default: () => true
        },
    },
    components: {
        Stepper,
        Step,
    },
    data() {
        return {}
    },
    computed: {
        menuList() {
            return this.components.map((m, step) => {
                let isValid = undefined;
                if (this.step >= step) {
                    isValid = !m.hasErrors
                }
                let icon = m.icon
                if(this.step >= step) {
                    if (isValid === false) {
                        icon = 'reefer-error'
                    } else
                    if (isValid) {
                        icon = 'common__checkbox'
                    }
                }
                return {...m, step, isValid, icon}
            })
        },
        componentsNoValid(){
            return this.menuList.filter( c => c.isValid === false ).map(c => c.component)
        },
        isValid(){
            return !this.componentsNoValid.length
        },
        isLastStep(){
            return this.step == (this.menuList.length - 1)
        },
        errors(){
            return this.components.filter(c => !!c.hasErrors).map(c => c.component)
        },
        subtitle(){
            let selected = this.components.find(c => c.component === this.component)
            return selected?.subtitle || ''
        },
        // fistStepNoValid(){
        //     return this.menuList.findIndex(c => c.isValid === false )
        // },
        // passedSteps(){
        //     return this.menuList.reverse().findIndex(c => c.isValid === true || c.isValid === false)
        // },
        component: {
            get(){ return this.modelValue },
            set(v){ this.$emit('update:modelValue', v) }
        },
        step: {
            get(){ return this.components.findIndex(c => c.component === this.component) || 0 },
            set(s){ this.component = this.components[s]?.component }
        },
        next(){
            return this.isLastStep ?  this.action : this.$t('buttons.Next')
        },
    },
    methods: {
        onCancel() {
            this.$emit('cancel')
        },
        onStepNext() {
            if(this.components[this.step]?.hasErrors){
                return false
            }
            if(this.isLastStep){
                return this.$emit('save')
            }
            this.step++
        },
        onStepBack() {
            if(this.step) this.step--
        },
        setStep(event){
            if(this.componentsNoValid.length && event.value > this.step) return;
            if((event.value <= this.step + 1)){
                this.step = event.value
            }
        },
    }
}
</script>

<style lang="scss">
.wizard{
    $widthHeight: 64px;
    $progressBarHeight: 6px;
    $successColor: #B2D135;
    $errorColor: var(--color-danger);
    $defaultSelectColor: #409AFA;

    background: #FCFDFF;
    box-shadow: 0 24px 24px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 40px 40px;
    padding: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1000px;
    height: 624px;

    .wizard-mb__12{
        margin-bottom: 12px;
    }
    .wizard-mb__44{
        margin-bottom: 44px;
    }

    &__body{
        height: 100%;
        padding: 0 100px;
        overflow: hidden;
    }
    .head__title{
        text-align: center;
        @include font(20px, 24px, 700, none, normal, #053D77);
        margin-bottom: 30px;
    }
    &__head{
        height: 90%;
        overflow: hidden;
    }
    &__bottom{
        padding: 0 112px;
        height: 10%;
    }
    .wizard__icon{
        width: 35px;
        height: 35px;
    }
    &__close{
        &.button_view_icon{
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }
    .wizard-navigation{
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .wizard__section{
        overflow: auto;
        @include scroll();

        .field-container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &__license-container {
                display: flex;
                justify-content: space-around;
                flex-direction: row;
            }

            & > div{
                width: 47%;
            }
            & > .field-row-full{
                width: 100%;
            }
            .field-groups{
                width: 100%;
                &.field-row{
                    width: 47%;
                }
                &.field-groups-position{
                    display: flex;
                    justify-content: space-between;
                    .field-row{
                        width: 47%;
                    }
                }
            }

            .select-wrap{
                width: 100%;
            }
            .field-group__img-item{
                width: 20%;
            }
            & > .field-group, .horizontal-line{
                width: 48%;
            }
            .field-row{
                .horizontal-line,
                .fast-user,
                .field-group_width_200{
                    width: 100%;
                }
                &__row-48{
                    width: 48%;
                }

                &__column{
                    flex-direction: column;
                }
                &__justify-end{
                    justify-content: flex-end;
                }
                &__justify-stert{
                    justify-content: flex-start;
                }
                &__wrap{
                    flex-wrap: wrap;
                }

                .checkbox-control{
                    margin-bottom: 0;
                }
            }
        }
    }

    .wizard-buttons{
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: flex-end;
    }

    .custom-stepper{
        margin-bottom: 20px;

        &.stepper-one{
            .k-progressbar{
                display: none;
            }
        }

        .k-step-list-horizontal ~ .k-progressbar{
            height: $progressBarHeight;
            top: calc(64px / 2);
        }

        .custom-step{
            width: $widthHeight;
            height: $widthHeight;
            background: #D9D9D9;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            position: relative;
            cursor: pointer;
            margin-bottom: 10px;
            //border: thick double #32a1ce; ;

            &:after{
                $afterPosition: calc(10px / 2);
                content: none;
                display: block;
                position: absolute;
                width: calc(100% + 10px);
                height: calc(100% + 10px);
                top: 0 - $afterPosition;
                left: 0 - $afterPosition;
                border: solid 2px black;
                border-radius: 100%;
                background: white;
                z-index: -1;
            }
        }

        .step-label{
            @include font(12px, 15px, 700, none, normal, #424242);
        }
    }

    .k-step{
        &.k-step-success, &.k-step-done {
            .custom-step{
                background: $successColor;
                color: white;
            }
            .step-label{
                color:$successColor;
            }
        }

        &.k-step-error {
            .custom-step{
                background: $errorColor;
                color: white;
            }
        }

        &.k-step-focus{

            .custom-step{
                background: $defaultSelectColor;
                &:after{
                    content:'';
                    border-color: $defaultSelectColor;
                }
            }

            &.k-step-success, &.k-step-done{
                .custom-step{
                    background: $successColor;
                    &:after{
                        content:'';
                        border-color: $successColor;
                    }
                }
            }

            &.k-step-error{
                .custom-step{
                    background: $errorColor;
                    &:after{
                        content:'';
                        border-color: $errorColor;
                    }
                }
                .step-label{
                    color:$errorColor;
                }
            }

        }

        .k-step-link{
            padding: 5px;
        }

    }

    .k-progressbar {
        .k-selected{
            background: $successColor;
        }
    }
}
</style>