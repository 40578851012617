<template>
    <div class="symbol__text-status">{{status}}</div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'UnitsList_unit_text_status',
        props: [
            'unitId',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "getAppUserUnits",

                "getTimeToday",
                "unitsByIds",
                "getUnitLicenseById",
                "unitsLmsgsByIds",
            ]),
            speedUnits(){
                return this.getAppUserUnits.speed
            },
            unit(){
                return this.unitsByIds[this.unitId]
            },
            isLicenseActive(){
                return this.getUnitLicenseById[this.unitId]?.isActive
            },
            maxSpeed(){
                return this.unit?.max_speed > 0 ? this.unit?.max_speed : ''
            },
            unitLmsg(){
                return this.unitsLmsgsByIds[this.unitId]
            },
            isParked(){
                return this.unitLmsg?.status?.isParked
            },
            isMoving(){
                return this.unitLmsg?.status?.isMoving
            },
            speed(){
                return (this.unitLmsg?.speed > 0) ? Math.round(this.unitLmsg?.speed) : ''
            },
            status() {
                return (!this.isLicenseActive) ? this.$t('License.Expired') :
                    (this.isParked) ? this.$t('text.Parked') :
                    (this.isMoving) ? this.speed + (this.maxSpeed > '' ? '/' + this.maxSpeed : '') + ' ' + this.speedUnits :
                    '';
            },
        },
        updated: function() {
           // console.log(`UnitsList_unit_status ${this.status} updated`);
        },
    }
</script>