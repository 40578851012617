<template>
    <UnitInfo__sensors_template
        :unitId="unitId"
        :icon="icon"
        :title="$t('Unit.sensors.Battery voltage')"
        :statusClass="statusClass"
        :statusIcon="statusIcon"
        :value="value"
    />
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import {mapGetters} from "vuex";
export default {
    props:{
        unitId: [String, Number],
        showIcon: {
            type: Boolean,
            default: () => true
        },
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_battery_voltage",
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters([
            'unitsLmsgsByIds',
        ]),
        unitLmsg() {
            return this.unitsLmsgsByIds[this.unitId]
        },
        unitLmsgSensors() {
            return this.unitLmsg?.sensors || {}
        },
        value() {
            return this.unitLmsgSensors?.battery?.text || '-'//this.$t('Not defined')
        },
        statusClass(){
            return ''
        },
        statusIcon(){
            return ''
        },
        icon() {
            return this.showIcon ? 'sensors__battary_voltage' : false
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>