<template>
    <UnitInfo__sensors_template
        :unitId="unitId"
        :icon="icon"
        :title="$t('Unit.sensors.Fuel')"
        :statusClass="statusClass"
        :statusIcon="statusIcon"
        :value="value || '-'"
        v-if="_showByValue"
    />
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import {mapGetters} from "vuex";
export default {
    props:{
        unitId: [String, Number],
        showIcon: {
            type: Boolean,
            default: () => true
        },
        showByValue: {
            type: Boolean,
            default: () => false,
        }
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_fuel_level",
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters([
            'unitsLmsgsByIds',
        ]),
        unitLmsg() {
            return this.unitsLmsgsByIds[this.unitId]
        },
        unitLmsgSensors() {
            return this.unitLmsg?.sensors || {}
        },
        value() {
            let value = this.unitLmsgSensors?.fuel_level
            if(typeof value != "string" || typeof value != "number") {
                value = value?.text
            }
            return value
        },
        _showByValue() {
            return this.showByValue ? !!this.value : true
        },
        statusClass(){
            return ''
        },
        statusIcon(){
            return ''
        },
        icon() {
            return this.showIcon ? 'reefer__fuel' : false
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>