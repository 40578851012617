const config = {
    "api": {
        "baseURL": "https://apitest.prometheuspro.us/api",
        // "baseURL": "https://api.prometheuspro.dev/api",
        // "baseURL": "https://apitest.xgps.us/api",
        // "ver": "v1",
    },
    "exports": {
        "baseURL": "https://apitest.prometheuspro.us/tmp/",
        // "baseURL": "https://api.prometheuspro.dev/tmp/",
        // "baseURL": "https://apitest.xgps.us/tmp/",
    },
    "customize": {
        "baseURL": "https://apitest.prometheuspro.us",
        // "baseURL": "https://api.prometheuspro.dev",
        // "baseURL": "https://apitest.xgps.us",
    },
    "privatelabel": {
        "baseURL": "https://apitest.prometheuspro.us",
        // "baseURL": "https://api.prometheuspro.dev",
        // "baseURL": "https://apitest.xgps.us",
    },
}

export default config