<template>
    <UnitInfo__sensors_template
        :unitId="unitId"
        :icon="icon"
        :title="$t('Unit.sensors.Year')"
        :statusClass="statusClass"
        :statusIcon="statusIcon"
        :value="value"
    />
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import {mapGetters} from "vuex";
export default {
    props:{
        unitId: [String, Number],
        showIcon: {
            type: Boolean,
            default: () => true
        },
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_year",
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters([
            "unitsByIds"
        ]),
        value() {
            // return this.unitsByIds[this.unitId]?.hw_type || '-'
            return '-'
        },
        statusClass(){
            return ''
        },
        statusIcon(){
            return ''
        },
        icon() {
            return this.showIcon ? false : false //ToDo need create icon
        }
    }
}
</script>

<style scoped>

</style>