const config = {
    "api": {
        // "baseURL": "https://apitest.xgps.us/api",
    },
    "exports": {
        // "baseURL": "https://apitest.xgps.us/tmp/",
    },
    "customize": {
        // "baseURL": "https://apitest.xgps.us",
    },
    "privatelabel": {
        // "baseURL": "https://apitest.xgps.us",
    },
}

export default config