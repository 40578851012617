<template>
    <FieldGroup__base_wrap
        class="field-group_type_combobox"
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :error="error"
        :warning="warning"
        :disabled="disabled"
    >
        <kendo-combobox
            :class="fieldGroup_input_class"
            :allow-custom="allowCustom"
            :data-items="items"
            :id="id"
            :name="id"
            :placeholder="placeholder_text"
            :value="modelValue"
            :disabled="disabled"
            @change="$emit('update:modelValue', $event.target.value)"
        />
    </FieldGroup__base_wrap>
</template>
<script>
    import {ComboBox} from '@progress/kendo-vue-dropdowns';

    export default {
        name: 'FieldGroup_k_combobox',
        emits:['update:modelValue'],
        props: {
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'id': String,
            'label': String,
            'modelValue': [Number, String, Array, Object],
            'placeholder': String,
            'error': {
                type: [String, Array, Object],
                default: () => ''
            },
            'warning': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info': String,
            'items': [Array, Object],
            'disabled': {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                allowCustom: true,
            }
        },
        components: {
            'kendo-combobox': ComboBox
        },
        computed: {
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter') + ' ' + this.label.toLowerCase()
            },
        },
        methods: {
        },
        mounted() {
        },
        updated() {
            //console.log('FieldGroup_select updated', this.id, this.error)
        },
    }
</script>
