// import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

export default {
    state: {},
    actions: {
        saveAccess({commit}, args) {
            return new Promise((resolve, reject) => {
                let userId = args.userId
                let objectName = args.objectName
                let data = args.data
                //let params = args.params
                //const params = {expand: consts.api.units.expand.join()}//, ...with_removed }//, fields: unitsFields.join()}
                this.$api.accessrights.setAllObjects(userId, objectName, data/*, params*/)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('');
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {},
    getters: {}
}
