<template>
    <FieldGroup__base_wrap
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :input_icon="input_icon"
        :error="error"
        :warning="warning"
        :disabled="disabled"
    >
        <select class="field-group__input"
                :class="fieldGroup_input_class"
                :id="id"
                :name="id"
                :placeholder="placeholder_text"
                :disabled="disabled"
                v-model="selected"
        >
            <option value="" hidden selected>{{ placeholder_text }}</option>
            <option v-for="item in list" :key="id+item.id" :value="item.id">
                {{ item.name }}
            </option>
        </select>
    </FieldGroup__base_wrap>
</template>
<script>
    export default {
        name: 'FieldGroup_select',
        emits:['update:modelValue'],
        props: {
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'id': String,
            'label': String,
            'modelValue': [Number, String, Array, Object],
            'placeholder': String,
            'error': {
                type: [String, Array, Object],
                default: () => ''
            },
            'warning': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info': String,
            'items': [String, Array, Object],
            'field_id': {
                type: [String],
                default: () => 'id'
            },
            'field_name': {
                type: [String],
                default: () => 'name'
            },
            'sortBy': {
                type: [String, Function],
                default: () => 'name'
            },
            'disableSortBy': Boolean,
            "input_icon": String,
            'disabled': {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {}
        },
        computed: {
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter') + ' ' + this.label.toLowerCase()
            },
            list() {
                let field_id = this.field_id
                let field_name = this.field_name
                let field_sortBy = (typeof this.sortBy === 'string') ? this.sortBy : ''
                let addFieldSortBy = (!this.disableSortBy && (typeof this.sortBy === 'string'))
                let list = []
                if (Array.isArray(this.items)) {
                    list = this.items.map(item => {
                        let listItem = {id: item[field_id], name: item[field_name]}
                        if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                        return listItem
                    })
                } else {
                    //console.warn('items isn\'t array', (typeof this.items))
                    const objectArray = Object.entries(this.items);
                    objectArray.forEach(([id, item]) => {
                        let name = item;
                        if (typeof item == 'object' && item[field_name] !== undefined) {//.hasOwnProperty
                            name = item[field_name]
                        }
                        if (typeof name == 'string') {
                            let listItem = {id, name}
                            if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                            list.push(listItem)
                        } else {
                            console.warn('item isn\'t string', id, item)
                        }
                    });
                }
                let sortBy = (typeof this.sortBy !== 'string') ? this.sortBy : 'sortBy'
                if (!this.disableSortBy) {
                    if (typeof sortBy === 'string') {
                        let field = '' + sortBy
                        sortBy = (a, b) => {
                            return (a[field] == b[field]) ? 0 :
                                (a[field] > b[field]) ? 1 : -1;
                        }
                    }
                    if (typeof sortBy === 'function') {
                        list.sort(sortBy);
                    } else {
                        console.warn('unknown method sortBy: ', typeof sortBy, sortBy)
                    }
                }
                return list
            },
            selected: {
                get() { return this.modelValue },
                set(v) { this.$emit('update:modelValue', v) }
            }
        },
        methods: {
        },
        mounted() {
        },
        updated() {
            //console.log('FieldGroup_select updated', this.id, this.error)
        },
    }
</script>
