const config = {
    //"img_baseURL": "https://portal.xgps.us/img/libauto/",

    "privatelabel": {
        "baseURL": "https://privatelabel.xgps.us",
    },
    "api": {
        "baseURL": "https://api.xgps.us/api",
        "ver": "v1",
    },
    "exports": {
        "baseURL": "https://api.xgps.us/tmp/",
    },
    "customize": {
        "baseURL": "https://api.xgps.us",
    },
}
export default config