<template>
    <div class="weather" :class="{'weather-extended': !extendedSize, 'weather-hide-on-map': !latlng}">
        <SpinnerIcon class="size-spinner" v-if="!hasUnitWeather && latlng" />
        <template v-else-if="latlng">
            <div class="weather__block">
                <div class="weather__time-info">
                    <div>
                        <div class="weather__time weather__font"><SectionCurrentDateTime :timezone-offset="getUserTimeZone" :format="getTimeFormat"/></div>
                        <div class="weather__date weather__font"><SectionCurrentDateTime :timezone-offset="getUserTimeZone" :format="getDateFormat"/></div>
                    </div>
                    <ul class="weather__info-list" v-if="listExtended.length && $consts.app.$isPrometheus">
                        <li class="weather__info-item weather__font weather__list-text" v-for="(item, i) in listExtended" :key="i" >
                            <img style="height: 18px; width: 18px" :src="require(`@/assets/image/weather__${item.icon}.svg`)" alt="">
                            <!-- <SvgIcon :name="'weather__'+item.icon" class="icon" style="width: 30px; height: 30px"/> -->
                            {{item.value}}<span class="weather__list-meaning ">{{ item.unit }}</span>
                        </li>
                    </ul>
                </div>
                <div class="weather__main-info">
                    <div class="weather-icon">
                        <SvgIcon class="icon weather-icon__icon" :name="weather_icon"/>
                    </div>
                    <div class="weather__value weather__font">
                        <img class="image" src="@/assets/image/gradus.png" alt="">
                        <div class="text info-units">
                            {{ temperature }}
                            <span class="badge">
                                <SvgIcon class="icon" style="width: 9px" name="circle__radius"/>
                                {{ temperatureUnits }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="weather__block" v-if="$consts.app.$isIntuit">
                <ul class="weather__info-list" v-if="listExtended.length">
                    <li class="weather__info-item weather__font weather__list-text" v-for="(item, i) in listExtended" :key="i" >
                        <img style="height: 30px; width: 30px" :src="require(`@/assets/image/weather__${item.icon}.svg`)" alt="">
                        <!-- <SvgIcon :name="'weather__'+item.icon" class="icon" style="width: 30px; height: 30px"/> -->
                        {{item.value}}<span class="weather__list-meaning ">{{ item.unit }}</span>
                    </li>
                </ul>
                <div class="weather__text weather__font">
                    {{weather_text}}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";
import SectionCurrentDateTime from "@/components/_base/SectionCurrentDateTime.vue";

export default {
    name: "WeatherWidget",
    components: {SectionCurrentDateTime},
    props: {
        'unitId': [String, Number],
        'extendedSize':{
            type: Number,
            default: () => { return 0 }
        }
    },
    data() {
        return {
            weathersList: [
                {name: 'wind',     icon: 'wind',},
                {name: 'humidity', icon: 'raindrop',},
                {name: 'pressure', icon: 'pressure',},
                // {name: 'precip',    icon: 'precip', },
                // {name: 'feelslike', icon: 'thermometer', },
                // {name: 'gust',      icon: 'wind', }
            ]
        }
    },
    computed:{
        ...mapGetters([
            "getAppUserSettings",
            "getUserTimeZone",
            "getTimeFormat",
            "getDateFormat",
            "getWeathers",
            "unitsLmsgsByIds",
            "getAppUserUnits",
            "getAppUserUnitsValue",
        ]),
        temperatureUnits(){
            return this.getAppUserUnits.temperature
        },
        latlng(){
            return (this.unitsLmsgsByIds[this.unitId] || {}).latlng
        },
        unitWeather(){
            if(!this.latlng) {
                //ToDo fix
                // if(this.unitId > 0) this.fetchUnitWeather(this.unitId)
                return false
            }
            // console.log('weather at latlng', {...this.latlng}, this.getWeathers.length)
            let weathers = this.getWeathers
                .filter(w => w.request_time > Date.now()/1000 -3600)
                .map(w => {
                    let lat = (w.latLng.lat*1.0) - (this.latlng.lat*1.0)
                    let lon = (w.latLng.lng*1.0) - (this.latlng.lng*1.0)
                    let distances = Math.sqrt(Math.pow(lat,2) + Math.pow(lon,2))
                    let time_ago = Date.now()/1000 - w.request_time
                    return (distances < 0.2)? {...w, distances, time_ago} : null
                })
                .filter(w => w)

            // console.log('weather at latlng', {...this.latlng}, weathers)
            weathers.sort(function (a,b){
                //let time = a.time_ago - b.time_ago
                return (a.distances - b.distances)/0.2// + (time/3600)
            })

            let weather = weathers[0]
            // console.log('weather at latlng', {...this.latlng}, {...weather}, weathers)
            return weather? {...weather} : null
        },
        hasUnitWeather(){
            return !!this.unitWeather
        },
        temperature(){
            let temperature = this.unitWeather?.temperature
            return temperature ? Math.round( temperature[this.temperatureUnits]) : '- '
        },
        weather_text(){
            return (this.unitWeather?.text) ? this.unitWeather.text[this.$i18n.locale] || this.unitWeather.text.en || this.unitWeather.text : ''
        },
        weather_icon(){
            return this.unitWeather?.icon ? ('weather__'+this.unitWeather.icon) : 'empty'
        },
        weatherExtended(){
            return this.unitWeather?.extendWeather || []
        },
        listExtended(){
            let weatherExtended = this.weathersList.map(w => {
                let item = this.weatherExtended[w.name]
                if(typeof item != 'object') {
                    item = null;
                } else {
                    item = Object.fromEntries(Object.entries(item).filter(([key]) => this.getAppUserUnitsValue.includes(key)));
                    if(item) item = {value: item[Object.keys(item)[0]], unit:Object.keys(item)[0],...w}
                }
                return item
            })
            .filter(w => w)
            return weatherExtended.slice(0,this.extendedSize)
        },
    },
    watch: {
        unitId: {
            handler() {
                this.debouncedFetchUnitWeather()
            },
        },
        latlng: {
            handler() {
                this.debouncedFetchUnitWeather()
            },
        },
        hasUnitWeather: {
            handler() {
                this.debouncedFetchUnitWeather()
            },
        },
    },
    methods:{
        ...mapActions([
            "fetchWeather",
            "fetchUnitWeather"
        ]),
        handlerUnitWeather(){
            // console.log('watch weather', this.unitId, this.latlng, this.hasUnitWeather, this.unitWeather)
            if (this.unitId && this.latlng && !this.hasUnitWeather) {
                // console.log('watch weather', 'fetchUnitWeather', this.unitId)
                this.fetchUnitWeather(this.unitId)
            }
        },
    },
    created: function () {
        this.debouncedFetchUnitWeather = debounce(this.handlerUnitWeather, 500)
    },
}
</script>

<style lang="scss" scoped>
.weather{
    background: var(--weather-bg);
    opacity: var(--weather-opasity);
    border-radius: 12px;
    padding: 15px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__block{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    &__font{
        font-family: 'Inter', sans-serif;
        font-style: normal;
        color: green;
        .time {
            all: inherit;
            color: white;
        }
    }
    &__meaning{
        font-size: 21px;
    }
    &__time{
        font-size: 32px;
        font-weight: 700;
        color: #FFFFFF;
    }
    &__date{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF
    }
    &__value{
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        color: white;
        display: flex;
        .text{
            padding-right: 15px;
            position: relative;
            margin-left: 10px;
        }
        .badge{
            display: flex;
            font-size: 33px;
            width: 32px;
            margin-left: 4px;
            padding-top: 10px;
        }
        .info-units{
            display: flex;
            align-items: center;
        }
        .image{
            height: 50px;
        }
    }
    &__info-list{
        display: flex;
        background: var(--weather-info-bg);
        border-radius: 12px;
        padding: 5px 10px;
    }
    &__info-item{
        display: flex;
        align-items: center;
        &:not(:last-child){
            margin-right: 10px;
        }
    }
    &__list-icon{
        width: 25.26px;
        height: 25.26px;
        border: 2px solid #AED0F3;
        border-radius: 100%;
        margin-right: 5px;
    }
    &__list-text{
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
    }
    &__list-meaning {
        font-weight: 400;
        font-size: 14px;
        margin-left: 5px;
    }
    &__text{
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        color: #AED0F3;
        width: 100%;
    }
    .weather-icon{
        position: relative;
        width: 100px;
        height: 50px;
        &__icon{
            position: absolute;
            top: -20px;
            left: 0;
            width: 100px;
            height: 100px;
        }
    }
}
.weather-extended{
    padding: 5px 12px 9px 12px;
    opacity: .9;
    .weather__main-info{
        display: flex;
        align-items: center;
    }
    .weather-icon{
        &__icon{
            width: 100%;
            height: 100%;
        }
        .icon{
            position: static;
        }
    }
    .weather__time{
        font-size: 20px;
    }
    .weather__date{
        font-size: 14px;
    }
    .weather__text{
        margin-left: 0;
        color: #e7f0f9;
    }
    .weather__value{
        font-size: 28px;
    }
    .weather__value {
        .image {
            height: 28px;
        }
    }
}
.prometheus-portal {
    .weather-in-map{
        left: 30px;
        right: auto;
        padding: 20px 10px 20px 0;
        &:after {
            content: '';
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            background: var(--weather-before-bg);
            opacity: var(--weather-before-opasity);
            border-radius: 12px;
            border: var(--weather-border);
            position: absolute;
            top: -10px;
            left: -10px;
            z-index: -1;
        }
        .weather__block {
            flex-direction: row-reverse;
        }
        .weather__time-info {
            border-left: solid 1px #8AA2B9;
            padding-left: 15px;
            margin-left: 15px;
        }
        .weather__time {
            font-size: 18px;
        }
        .weather__date {
            font-size: 13px;
        }
        .weather-icon{
            width: 50px;
            height: 42px;
            &__icon{
                width: 80px;
                height: 80px;
            }
        }
        .weather__value {
            .text {
                font-size: 20px;
                padding: 0;
                margin-left: 26px;
            }
            .image {
                display: none;
            }
        }
        .weather__info-list {
            padding-left: 0;
            padding-right: 0;
        }
        .weather__main-info {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .weather__list-meaning,
        .weather__list-text {
            font-size: 10px;
        }
    }
}
</style>
<style lang="scss">
.weather-extended{
    .weather-no-extended{
        height: 76px;
        width: 216px;
        .loader-spinner{
            height: 20%;
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.weather-in-map{
    position: absolute;
    right: 5rem;
    top: 1.6rem;
    z-index: 400;
    padding: 5px 15px 4px;
    &.weather-hide-on-map{
        display: none;
    }
    .size-spinner{
        height: 76px;
        width: 216px;
        .loader-spinner{
            height: 20%;
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .weather__meaning{
        font-size: 16px;
    }
    .weather__time{
        font-size: 28px;
    }
    .weather__time-info{
        margin-right: 20px;
    }
    .weather__date{
        font-size: 16px;
    }
    .weather__value{
        font-size: 30px;
        .text{
            padding-right: 15px;
            position: relative;
            margin-left: 10px;
        }
        .badge{
            font-size: 24px;
            padding-top: 0;
        }
        .image{
            height: 30px;
        }
    }
    .weather__info-list{
        display: flex;
        background: var(--weather-info-bg);
        border-radius: 12px;
        padding: 5px 10px;
    }
    .weather__info-item{
        display: flex;
        align-items: center;
        margin-right: 0;
        height: 20px;
    }
    .weather__list-icon{
        width: 14px;
        height: 14px;
        border: 2px solid #AED0F3;
        border-radius: 100%;
        margin-right: 5px;
    }
    .weather__list-text{
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
    }
    .weather__list-meaning {
        font-size: 11px;
    }
    .weather__text{
        font-size: 12px;
        text-align: left;
        width: 113px;
        margin-left: 20px;
    }
    .weather-icon{
        position: relative;
        width: 70px;
        height: 62px;
        &__icon{
            position: absolute;
            top: -20px;
            left: 0;
            font-size: 20px;
            width: 100px;
            height: 100px;
        }
    }
}
</style>