<template>
    <UnitInfo__sensors_template
        v-for="output in outputs"
        :key="output.index"
        :unitId="unitId"
        :icon="output.icon"
        :title="output.title"
        :statusClass="output.statusClass"
        :statusIcon="output.statusIcon"
        :value="output.value"
        :valueText="output.valueText"
    />
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import {mapGetters} from "vuex";


export default {
    props:{
        unitId: [String, Number],
        showIcon: {
            type: Boolean,
            default: () => true
        },
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_outputs",
    data() {
        return {
            title: this.$i18n.t('Unit.Battery'),
            icon: 'unit_dashboard__battery',
            optionsByValue:{
                'on': {
                    icon: 'output',
                    iconContainerClass: '',
                    value: 'On',
                    statusClass: '',
                    statusIcon: '',
                },
                'off':{
                    icon: 'output',
                    iconContainerClass: '',
                    value: 'Off',
                    statusClass: '',
                    statusIcon: '',
                },
                'null':{
                    icon: 'output',
                    iconContainerClass: '',
                    value: '-',
                    statusClass: '',
                    statusIcon: '',
                }
            }
        }
    },
    computed:{
        ...mapGetters([
            'unitsByIds',
            "unitsOutputsById",
        ]),
        unit() {
            return this.unitsByIds[this.unitId] || {}
        },
        outputs() {
            let outputs = this.unitsOutputsById[this.unitId] || []
            return outputs.map((out, i) => {
                let options = this.optionsByValue[out.value];
                return {
                    index: i,
                    icon: this.showIcon ? options.icon : false,
                    iconContainerClass: options.iconContainerClass,
                    title: this.$te('output.types.' + out.name) ? this.$t('output.types.' + out.name) : out.name,
                    statusClass: options.statusClass,
                    statusIcon: options.statusIcon,
                    value: this.$t(options.value),
                    valueText: '',//out.name,
                    view: out.view,
                }
            }).filter(inp => inp.view)
        },
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>