// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.admin') ? [] : [
    {
        path: '/subscriptions',
        name: consts.routerNames.subscriptions.main,
        meta: {
            mainRouteName: consts.routerNames.subscriptions.main,
            bodyGrid: "1X1",
            setRoute: {
                subscriptions: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.subscriptions.main) {
                        if(this.$isDevelopment) console.warn('subscriptions', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.subscriptions.main,
                        params: {},
                        query: {}
                    })
                },
                subscriptionsUnitEdit: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.subscriptions.main) {
                        if(this.$isDevelopment) console.warn('subscriptionsUnitEdit', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.subscriptions.edit.unit,
                        params: {
                            unitId
                        },
                        query: {}
                    })
                },
                subscriptionsUserEdit: (route, router, userId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.subscriptions.main) {
                        if(this.$isDevelopment) console.warn('subscriptionsUserEdit', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.subscriptions.edit.user,
                        params: {
                            userId
                        },
                        query: {}
                    })
                },
                updateUnitLicence: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.subscriptions.main) {
                        if(this.$isDevelopment) console.warn('updateUnitLicence', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.subscriptions.update_unit_licence,
                        params: {
                            unitId
                        },
                        query: {}
                    })
                },
            }
        },
        component: () => import('@/views/intuit/admin/SubscriptionsPage.vue'),
        children: [
            {
                path: ':unitId/update-licence',
                name: consts.routerNames.subscriptions.update_unit_licence,
                meta: {
                    sectionWidgetByRoute: true
                },
                props: {
                    sectionWidgetByRoute: (route) => ({
                        unitId: route.params?.unitId,
                        time: Date.now(),
                        options: {components: ['UnitEdit_License_intuit']},
                    })
                },
                components: {
                    sectionWidgetByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                },
            },
            {
                path: ':unitId/edit',
                name: consts.routerNames.subscriptions.edit.unit,
                meta: {
                    sectionWidgetByRoute: true
                },
                props: {
                    sectionWidgetByRoute: (route) => ({
                        userId: route.params?.userId,
                        time: Date.now(),
                        options: {components: ['UnitEdit_License_intuit']},
                    })
                },
                components: {
                    sectionWidgetByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                },
            },
            {
                path: ':userId/edit',
                name: consts.routerNames.subscriptions.edit.user,
                meta: {
                    sectionWidgetByRoute: true
                },
                props: {
                    sectionWidgetByRoute: (route) => ({
                        userId: route.params?.userId,
                        time: Date.now(),
                        options: {components: ['UserEdit_License_renewal_intuit']},
                    })
                },
                components: {
                    sectionWidgetByRoute: () => import('@/components/users/sectionInfo/UserEdit.vue'),
                },
            }
        ]
    },
];

export default routes;