// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.admin') ? [] : [
    {
        path: '/recycle-bin',
        name: consts.routerNames.recycle_bin.main,
        meta: {
            mainRouteName: consts.routerNames.recycle_bin.main,
            bodyGrid: "1X1",
            setRoute: {
                recycleBin: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.recycle_bin.main) {
                        if(this.$isDevelopment) console.warn('recycleBin', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.recycle_bin.main,
                        params: {},
                        query: {}
                    })
                },
                unitsEdit: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.recycle_bin.main) {
                        if(this.$isDevelopment) console.warn('unitsEdit', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.recycle_bin.edit.unit,
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })
                },
                unitsRestore: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.recycle_bin.main) {
                        if(this.$isDevelopment) console.warn('unitsRestore', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.recycle_bin.restore,
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })
                },
                unitsHistory: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.recycle_bin.main) {
                        if(this.$isDevelopment) console.warn('unitsHistory', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.recycle_bin.history.unit,
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })
                },
                usersHistory : (route, router, userId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.recycle_bin.main) {
                        if(this.$isDevelopment) console.warn('usersHistory', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.recycle_bin.history.user,
                        params: {
                            userId,
                        },
                        query: {...route.query}
                    })
                },
            }
        },
        component: () => import('@/views/intuit/admin/RecycleBinPage.vue'),
        children: [
            {
                path: ':unitId/history',
                name: consts.routerNames.recycle_bin.history.unit,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            class: 'popup-full',
                            titlePosition: 'left'
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        objectName: 'unit',
                        objectId: route.params.unitId,
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/objects_audit/objects/ObjectsChangesTableTreeList.vue'),
                }
            },
            {
                path: ':userId/history',
                name: consts.routerNames.recycle_bin.history.user,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            class: 'popup-full',
                            titlePosition: 'left'
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        objectName: 'user',
                        objectId: route.params.userId,
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/objects_audit/objects/ObjectsChangesTableTreeList.vue'),
                }
            },
            {
                path: ':unitId/restore',
                name: consts.routerNames.recycle_bin.restore,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            // class: 'popup-full',
                            titlePosition: 'center'
                        }
                    },
                    // sectionWidgetByRoute: true,
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        unitId: route.params.unitId,
                        time: Date.now(),
                        options: {components: ['UnitEdit_License_intuit']},
                        restore: true,
                        wrapper: 'EditWizard',
                        class: 'popup-body',
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                },
            },
        ]
    },
];

export default routes;