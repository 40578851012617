<template>
    <div class="head__dropdown-menu dropdown-menu dropdown-menu_height_max"
         :class="{'dropdown-menu_open': showMenu}"
    >
        <div class="dropdown-menu__wrap">
            <ul class="dropdown-menu__list">
                <li class="dropdown-menu__item item item_type_user">
                    <button class="button button_view_user-name" >
                        {{userLetters}}
                    </button>
                    <div class="dropdown-menu__item-wrap">
                        <span class="dropdown-menu__user-name">{{userName}}</span>
                        <span v-if="isDealer" class="dropdown-menu__balance">{{ $t('text.Balance') }}: {{unitsKeysFreeSum}}</span>
                    </div>
                </li>

                <template v-if="this.$product == 'prometheus' && this.$isPortalSite">
                <li class="dropdown-menu__item item item_type_separator"></li>
                <li class="dropdown-menu__item item item_type_subtitle" v-if="subusers.length">
                    <h5>{{ $t('text.Subusers') }}</h5>
                </li>
                <li class="dropdown-menu__item item"
                    v-for="user in subusers"
                    :key="'subuser'+user.id"
                >
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__user" /></span> </span>
                    <span class="dropdown-menu__item-name">{{user.login}}</span>
                </li>
                </template>

                <template v-if="this.$product == 'prometheus' && this.$isPortalSite">
                <li class="dropdown-menu__item item item_type_separator"></li>
                <li class="dropdown-menu__item item" @click="displaySectionWidget('ImportExport')">
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__import_export" /></span> </span>
                    <span class="dropdown-menu__item-name">{{ $t('menu.Import / Export') }}</span>
                </li>
                <li class="dropdown-menu__item item" @click="displaySectionWidget('Locator')">
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__locator" /></span> </span>
                    <span class="dropdown-menu__item-name">{{ $t('menu.Locator') }}</span>
                </li>
                <li class="dropdown-menu__item item" @click="displaySectionWidget('Support')">
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__support" /></span> </span>
                    <span class="dropdown-menu__item-name">{{ $t('menu.Support') }}</span>
                </li>
                <li class="dropdown-menu__item item" @click="displaySectionWidget('Chat')">
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__chat" /></span> </span>
                    <span class="dropdown-menu__item-name">{{ $t('menu.Chat') }}</span>
                </li>
                <li class="dropdown-menu__item item" @click="displaySectionWidget('Docs')">
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__docs" /></span> </span>
                    <span class="dropdown-menu__item-name">{{ $t('menu.Docs') }}</span>
                </li>
                </template>

                <template v-if="true">
                <li class="dropdown-menu__item item item_type_separator"></li>
                <li class="dropdown-menu__item item"
                    @click="displaySectionWidget('UserSettings')"
                >
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__settings" /></span> </span>
                    <span class="dropdown-menu__item-name">{{ $t('menu.Settings') }}</span>
                </li>
                <li class="dropdown-menu__item item"
                    @click="displaySectionWidget('UserChangePassword')"
                >
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__password" /></span> </span>
                    <span class="dropdown-menu__item-name">{{ $t('menu.Change Password') }}</span>
                </li>
                <li class="dropdown-menu__item item" v-if="!user.is_admin"
                    @click="displaySectionWidget('UserUpgrade')"
                >
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__upgrade" /></span> </span>
                    <span class="dropdown-menu__item-name">{{ $t('menu.Upgrade to dealer account') }}</span>
                </li>
                </template>

                <li class="dropdown-menu__item item item_type_separator"></li>
                <li class="dropdown-menu__item item" @click="onLogout">
                    <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__exit" /></span> </span>
                    <span class="dropdown-menu__item-name">{{ $t('menu.Logout') }}</span>
                </li>

            </ul>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "UserDropdownMenu",
        props: ['showMenu'],
        components: {
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "apiToken",
                "isDealer",
                "getAppUser",
                "getAppUserLetters",
                "getUsers",
                "unitsKeysFreeSum"
            ]),
            user(){
                return this.getAppUser
            },
            userName(){
                return (this.getAppUser)? this.getAppUser.login : ''
            },
            userLetters(){
                return this.getAppUserLetters
            },
            subusers(){
                return this.getUsers
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
                "setSectionWidget",
                "logout",
            ]),
            displaySectionWidget(component) {
                this.setSectionWidget({
                    component: component,
                    props: {}
                })
            },
            onLogout(){
                //this.$cookies.set('authtoken', '')
                this.logout()
            },
            goto(path){
                this.$router.push('/'+path)
            }
        },
        mounted() {
            // console.log('UserDropdownMenu', this)
        },
        updated() {
            //console.log('FieldGroup_input updated', this.id, this.error)
        },
    }
</script>

<style scoped>

</style>