<template>
    <div class="field-group" :class="fieldGroup__class" :style="{opacity: disabled ? .6 : 10}">
        <label class="field-group__label" :class="fieldGroup_label_class" :for="id">
            <span v-if="labelIcon">
                <SvgIcon :name="labelIcon" class="field-group__label-icon"/>
            </span>
            <span>{{ label }}</span>
        </label>

        <slot></slot>

        <span class="field-group__icon icon" v-if="input_icon" data-toggle="">
            <span class="icon__symbol">
                <SvgIcon class="icon" :name="input_icon"/>
            </span>
        </span>
        <span class="field-group__help" :class="fieldGroup_info_class">
            <ul class="field-group__help__error-list">
                <template v-for="(warning, i) in warnings" :key="i">
                    <li class="field-group__help__error-list-warning">
                        <SvgIcon class="field-group__help__error-list-warning__icon" name="common__warning"/>
                        {{ warning }}
                    </li>
                </template>
                <template v-for="(error, i) in errors" :key="i">
                    <li class="">{{ error }}</li>
                </template>
            </ul>
            <span class="field-group__help__info">{{ info }}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: "FieldGroup__base_wrap",
    props: {
        'fieldGroup_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_label_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_info_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'disabled': {
            type: Boolean,
            default: () => false
        },
        'label': String,
        'labelIcon': String,
        'id': String,
        'info': String,
        'input_icon': String,
        'error': {
            type: [String, Array, Object],
            default: () => ''
        },
        'warning': {
            type: [String, Array, Object],
            default: () => ''
        },
    },
    data() {
        return {}
    },
    computed: {
        fieldGroup__class() {
            let fieldGroup_class = this.fieldGroup_class
            let type = (typeof this.fieldGroup_class)
            if (type === 'string') {
                return fieldGroup_class
                    + ((this.error > '') ? ' has-error ' : '')
                    + ((this.warning > '') ? ' has-warning ' : '')
                    + ((this.disabled === true) ? ' is_disabled ' : '')
            } else
            if (type === 'object') {
                if (Array.isArray(this.fieldGroup_class)) {
                    if (this.error > '') fieldGroup_class.push('has-error')
                    if (this.warning) fieldGroup_class.push('has-warning')
                    if (this.disabled === true) fieldGroup_class.push('is_disabled')
                    return fieldGroup_class
                } else {
                    return {
                        'is_disabled': (this.disabled === true),
                        'has-error': (this.error > ''),
                        'has-warning': (this.warning > ''),
                        ...fieldGroup_class
                    }
                }
            }
            console.error('FieldGroup_input::fieldGroup__class', type, fieldGroup_class)
            return this.fieldGroup_class
        },
        errors() {
            if (!this.error) return []
            let type = (typeof this.error)
            if (type === 'string') {
                return [this.error]
            } else
            if (type === 'object' && Array.isArray(this.error)) {
                return this.error.reduce( (errors , e) => {
                    if(e.$message > ''){//vuelidate
                        errors.push(e.$message)
                    } else
                    if(e.$response){//vuelidate api
                        if(e.$response.$messages && e.$response.$messages.length)
                            errors.push(...e.$response.$messages)
                    } else
                    if (typeof e === 'string') {// string
                        errors.push(e)
                    } else {
                        console.warn('errors:', e)
                    }
                    return errors
                },[])
            }
            console.error('FieldGroup_input::errors', type, this.error)
            return []
        },
        warnings() {
            if (!this.warning) return []
            let type = (typeof this.warning)
            if (type === 'string') {
                return [this.warning]
            } else
            if (type === 'object' && Array.isArray(this.warning)) {
                return this.warning
            } else {
                let keys = Object.keys(this.warning)
                return  keys.map(k => this.warning[k])
            }
        },
    }
}
</script>

<style scoped>

</style>