<template>
    <UnitInfo__sensors_template
        :unitId="unitId"
        :icon="icon"
        :title="$t('Unit.sensors.Status')"
        :statusClass="statusClass"
        :statusIcon="statusIcon"
        :value="value"
    />
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import {mapGetters} from "vuex";

export default {
    props:{
        unitId: [String, Number],
        showIcon: {
            type: Boolean,
            default: () => true
        },
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_status",
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters([
            'unitsLmsgsByIds',
            "unitsLmsgsTimeAgoByIds"
        ]),
        unitLmsg() {
            return this.unitsLmsgsByIds[this.unitId]
        },
        unitLmsgSensors() {
            return this.unitLmsg?.sensors || {}
        },
        unitLmsgStatus() {
            return this.unitLmsg?.status || {}
        },
        unitLmsgTimeAgo() {
            return this.unitsLmsgsTimeAgoByIds[this.unitId]
        },
        value() {
            let status = '-'
            if (this.unitLmsgStatus?.isMoving) {
                status = this.$t('Units.movements.Moving')
            } else
                // if (this.unitLmsgStatus?.ignition?.value) {
                //     status = this.$t('Units.movements.Ignition on')
                // } else
            if (this.unitLmsgStatus?.isParked) {
                status = this.$t('Units.movements.Stopped')
            }

            return status
        },
        statusClass(){
            let timeAgo = this.unitLmsgTimeAgo
            if (!timeAgo && timeAgo !== 0) {
                return '';
            }
            //if(timeAgo<0) timeAgo *= -1

            if (timeAgo <= 5*60) {
                return 'update_status_online'
            } else
            if (timeAgo <= 15*60) {
                return 'update_status_online-recently'
            } else
            if (timeAgo <= 60*60) {
                return 'update_status_not-online-recently'
            } else
            {
                return 'update_status_not-online-long-time'
            }
        },
        statusIcon(){
            let timeAgo = this.unitLmsgTimeAgo
            return  timeAgo <= 60*60 ? 'status_online' : 'status_offline'
        },
        icon() {
            return this.showIcon ? 'car_' : false
        }
    }
}
</script>

<style scoped>

</style>