// import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

import {arrayFilter_OnlyUnique} from "@/lib/lib";
import i18n from "@/i18n";

export default {
    state: {
        videoEvents_index: [],
        videoEvents: [],
    },
    actions: {
        fetchLastVideoEvents({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLastVideoEvents', time: Date.now() / 1000})

                let last = args?.last || '1 minutes'
                let params = {withAddress: 1, ...args.params}
                //if(!args.force) params.lastTimes = getters.getVideoEventsLastTimeByUnitIds
                this.$api.videoEvents.getLast(last, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('storeVideoEvents', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLastVideoEvents', inprogress: false})
                    });
            })
        },
        fetchVideoEvents4Date({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                if (!getters.apiToken || !(args.date > '')) {//!args.unitId ||
                    return reject(false)
                }
                if (args.unitId > 0) {
                    let index = getters.getVideoEventsIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
                    if (index) {
                        return resolve(true)
                    }
                }

                dispatch('setLastCall', {name: 'fetchVideoEvents4Date', time: Date.now() / 1000})
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastVideoEvents', time: Date.now() / 1000})
                }

                let params = {withAddress: 1, ...args.params}
                this.$api.videoEvents.get4Date(args.unitId, args.date, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('storeVideoEvents', response.data)
                            if (!params.limit || response.data.length < params.limit) {
                                commit('setVideoEventsIndex', {...args, count: response.data.length})
                                if (!args.unitId && response.data.length) {
                                    response.data
                                        .map(e => e.unit_id)//toDo all units forEach
                                        .filter(arrayFilter_OnlyUnique)
                                        .forEach(unitId => {
                                            commit('setVideoEventsIndex', {...args, unitId, count: 1})
                                        })
                                }
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchVideoEvents4Date', inprogress: false})
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastVideoEvents', inprogress: false})
                        }
                    });
            })
        },
        fetchVideoEvents4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                    return reject(false)
                }
                if (args.unitId > 0) {
                    let event = getters.getVideoEvents.find(e => (e.unit_id == args.unitId && e.time?.utc >= args.from && e.time?.utc <= args.to))
                    if (event) {
                        return resolve(true)
                    }
                }
                dispatch('setLastCall', {name: 'fetchVideoEvents4Date', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastVideoEvents', time: Date.now() / 1000})
                }

                let params = {withAddress: 1, ...args.params}
                this.$api.videoEvents.get4Period(args.unitId, args.from, args.to, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('storeVideoEvents', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchVideoEvents4Date', inprogress: false})
                        /* getters.getTimeToday+24h and args.from/to intersect*/
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastVideoEvents', inprogress: false})
                        }
                    });
            })
        },
        reloadVideoEvents({commit, dispatch}, args) {
            commit('clearVideoEvents')
            return dispatch('fetchVideoEvents4Date', {date: 'today', ...args})
        },

        //sayHello() {}
    },
    mutations: {
        setVideoEventsIndex(state, params) {
            let i = state.videoEvents_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.videoEvents_index.push(Object.freeze({...params}))
            } else {
                state.videoEvents_index[i] = Object.freeze({...state.videoEvents_index[i], ...params})
            }
        },
        storeVideoEvents(state, nEvents) {
            nEvents = nEvents
                .filter((e, i, _events) => (i === _events.findIndex(_e => _e.xid == e.xid)))
                .filter(e => !state.videoEvents.find(se => se.xid == e.xid))
                .map(e => Object.freeze(e))

            // if(nEvents.length) state.videoEvents.push(...nEvents)
            if (!nEvents.length) return false
            if (!state.videoEvents.length) {
                state.videoEvents = nEvents
                return true
            }
            state.videoEvents.push(...nEvents)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nEvents)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.videoEvents.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearVideoEvents(state) {
            state.videoEvents = [];
            state.videoEvents_index = [];
        },
    },
    getters: {
        getNewVideoEventsCount(state, getters) {
            let lastVisit = getters.getLastVisitNotifications
            if (getters.getTimeToday > lastVisit) lastVisit = getters.getTimeToday
            return state.videoEvents.filter(e => e.time?.utc > lastVisit).length
        },
        getVideoEventsIndex(state) {
            return state.videoEvents_index
        },
        getVideoEvents(state) {
            return state.videoEvents
        },
        getVideoEventsI18nMessages(state) {
            return state.videoEvents
                .map((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = ['message', event.hw_type, str].join('.')
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    return {type: event.type, xid: event.xid, message};
                })
        },
        getVideoEventsI18nMessagesByXid(state) {
            return state.videoEvents
                .reduce((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = ['message', event.hw_type, str].join('.')
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    eventsTextByXid[event.xid] = message;// || i18n.global.t('message.Unknown')//???
                    return eventsTextByXid;
                }, {})
        },
        getVideoEventsLastTimeByUnitIds(state) {
            return state.videoEvents
                //.filter(e => e?.time?.utc >= getters.getTimeToday)
                .reduce((timeByUnitIds, event) => {
                    timeByUnitIds[event.unit_id] = event?.time?.utc > timeByUnitIds[event.unit_id] ? event?.time?.utc : timeByUnitIds[event.unit_id]
                    return timeByUnitIds
                }, {})
        },
    }
}
